import { Icon } from '@iconify/react'
import React, { useEffect, useState } from 'react'
import { HiDesktopComputer, HiEye, HiOutlineDesktopComputer, HiOutlineDeviceMobile } from 'react-icons/hi'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'
import Dialog from '../Dialog'
import Alert from '../Alert'

const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({children}) => {
	return <span className="text-4xl">{children}</span>
}

const FileItem = props => {

	const { file, children, desktopWidth, desktopHeight, mobileWidth, mobileHeight, actualDesktopWidth, actualDesktopHeight, actualMobileWidth, actualMobileHeight } = props
	const { type, name, size } = file

	const [image, setImage] = useState(URL.createObjectURL(file))

	const renderThumbnail = () => {
		if (!type) {
		  return null; // or handle the case where type is undefined
		}
	  
		const isImageFile = type.split('/')[0] === 'image';
	  
		if (isImageFile) {
		  return <img
			className="upload-file-image"
			src={image}
			alt={`file preview ${name}`}
		  />;
		}
	  
		if (type === 'application/zip') {
		  return <FileIcon><VscFileZip /></FileIcon>;
		}
	  
		if (type === 'application/pdf') {
		  return <FileIcon><VscFilePdf /></FileIcon>;
		}
	  
		return <FileIcon><VscFile /></FileIcon>;
	  };

	const [selectedImg, setSelectedImg] = useState({})
	const [viewOpen, setViewOpen] = useState(false)
	const [reminder, setReminder] = useState(false);

	const onViewOpen = (img, type) => {
		loadImageDimensions(img)

		setSelectedImg({
			name: file.name,
			img: img,
			type: type
		})
		setViewOpen(true)
	}

	const onDialogClose = () => {
		setViewOpen(false)
		setTimeout(() => {
			setSelectedImg({})
		}, 300)
	}

	const [imageWidth, setImageWidth] = useState(null);
	const [imageHeight, setImageHeight] = useState(null);

	const loadImageDimensions = (imageSrc) => {
		const img = new Image();
		img.src = imageSrc;
		
		img.onload = function () {
		  setImageWidth(img.width);
		  setImageHeight(img.height);
		};

		if((imageWidth / imageHeight) !== (actualDesktopWidth / actualDesktopHeight)) {
			setReminder(true)
		}
	};

	useEffect(() => {
		loadImageDimensions(image)
	}, []) 

	return (
		<>
		<div className="upload-file">
			<div className='w-full'>
			<div className='p-3'>
				{reminder && <Alert showIcon className="mb-4">
					Uploaded image size: {imageWidth} x {imageHeight}. 
					<br />For optimal display, consider uploading images that match these dimensions.
				</Alert>}
			</div>
			<div className='flex w-full'>
			<div className='flex' style={{ width: '80%' }}>
				<div className="flex items-center p-3 gap-3">
					<div className="upload-file-thumbnail group flex relative p-0"
						style={{ width: `${desktopWidth}px`, height: `${desktopHeight}px` }}
					>
						{renderThumbnail()}
						<div className="absolute inset-0 bg-gray-900/[.7] group-hover:flex hidden text-xl items-center justify-center">
							<span
								onClick={() => onViewOpen(image, 'desktop')}
								className="text-gray-100 hover:text-gray-300 cursor-pointer p-1"
							>
								<Icon icon="ion:desktop-outline" />
							</span>
							<span
								onClick={() => onViewOpen(image, 'desktop')}
								className="text-gray-100 hover:text-gray-300 cursor-pointer p-1"
							>
								<HiEye />
							</span>
						</div>
					</div>
					<div className="upload-file-thumbnail group flex relative p-0"
						style={{ width: `${mobileWidth}px`, height: `${mobileHeight}px` }}
					>
						{renderThumbnail()}
						<div className="absolute inset-0 bg-gray-900/[.7] group-hover:flex hidden text-xl items-center justify-center">
							<span
								onClick={() => onViewOpen(image, 'mobile')}
								className="text-gray-100 hover:text-gray-300 cursor-pointer p-0.5"
							>
								<HiOutlineDeviceMobile />
							</span>
							<span
								onClick={() => onViewOpen(image, 'mobile')}
								className="text-gray-100 hover:text-gray-300 cursor-pointer p-1"
							>
								<HiEye />
							</span>
						</div>
					</div>
					<div className="upload-file-info">
						<h6 className="upload-file-name">{name}</h6>
						<span className="upload-file-size">{getKB(size)} kb</span>
					</div>
				</div>
			</div>
			<div className='flex justify-end items-center' style={{ width: '20%' }}>
				{children}
			</div>
			</div>
			</div>
		</div>
		<Dialog
			isOpen={viewOpen}
			onClose={onDialogClose}
			onRequestClose={onDialogClose}
			contentClassName='preview-dialog'
			afterOpenClassName='preview-dialog-open'
		>
			<h5 className="mb-1">{selectedImg.name}</h5>
			{reminder && <span style={{ color: '#ef4444' }}>
				Recommended size: {selectedImg.type === "desktop" ? actualDesktopWidth ? actualDesktopWidth : desktopWidth : actualMobileWidth ? actualMobileWidth : mobileWidth} 
				&nbsp;x {selectedImg.type === "desktop" ? actualDesktopHeight ? actualDesktopHeight : desktopHeight : actualMobileHeight ? actualMobileHeight : mobileHeight}. 
				Uploaded image size: {imageWidth} x {imageHeight}. 
				<br />For optimal display, consider uploading images that match these dimensions.
			</span>}
			<div className='mb-3'/>
			<div style={{ 
				width: `${selectedImg.type === "desktop" ? 
				actualDesktopWidth ? actualDesktopWidth : desktopWidth : 
				actualMobileWidth ? actualMobileWidth : mobileWidth}px`, 
				height: `${selectedImg.type === "desktop" ? 
				actualDesktopHeight ? actualDesktopHeight : desktopHeight : 
				actualMobileHeight ? actualMobileHeight : mobileHeight}px`,
			}}>
				<img
					className="upload-file-image"
					src={selectedImg.img}
					alt='...'
				/>
			</div>
		</Dialog>
		</>
	)
}

export default FileItem
