export const notificationListData = [
    // {
    //     id: 'b06ca3f5-8fb0-4979-a016-30dfe63e8fd6',
    //     target: 'Jean Bowman',
    //     description: 'invited you to new project.',
    //     date: '4 minutes ago',
    //     image: 'thumb-8.jpg',
    //     type: 0,
    //     location: '',
    //     locationLabel: '',
    //     status: '',
    //     readed: false
    // }
]

export const searchQueryPoolData = [
    // {
    //     title: 'Project Dashboard',
    //     url: '/app/project/dashboard',
    //     icon: 'project',
    //     category: 'Project',
    //     categoryTitle: 'Apps',
    // }
]
