import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getBookingNotificationList, notification, patchBookingNotification } from "services/CommonService";

export const initialData = {
  refresh : false,
  notification_count: {
    product: 0,
    order_ticket: 0,
    booking: 0,
  },
  post_topic: [
    {
      topic: 'Help Post', 
      value: 'help',
      description: `This is the place for you when you're stuck with a car problem. It can be anything: a broken part, confusing instructions, or just not knowing what car to buy. You ask your question, and others in the community will try to help by giving advice or sharing their experiences.`,
    },
    {
      topic: 'Show Case', 
      value: 'showcase',
      description: `Got something cool to show about your car? This is the spot. It might be a big fix you did yourself, a shiny new part you've added, or just how great your car looks when it's clean. You can share pictures, write about what you've done, and people will admire your work.`,
    },
    {
      topic: 'Sharing', 
      value: 'recommendation',
      description: `Ever found something really good and want to share with others? That's what this section is for. Maybe you discovered a great car cleaning product, or found a mechanic who does a great job. By posting in this section, you can let others know about these good things. This way, we all learn from each other and can find the best stuff out there..`,
    }
  ],
  notificationStatus : true,
  showNewBooking: false,
  showNewOrder: false,
  notificationList: [],
}

export const getNotification = createAsyncThunk(
  "common/data/get/notification",
  async (data, thunkAPI) => {
    try {
      const response = await notification(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getBookingNotification = createAsyncThunk(
  "common/data/get/notification_list",
  async (data, thunkAPI) => {
    try {
      const response = await getBookingNotificationList(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const readBookingNotification = createAsyncThunk(
  "common/data/patch/notification_list",
  async (data, thunkAPI) => {
    try {
      const response = await patchBookingNotification(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const dataSlice = createSlice({
  name: "common/data",
  initialState: initialData,
  reducers: {
    setRefresh: (state, action) => {
      state.refresh = !state.refresh
    },
    setOrderTicket: (state, action) => {
      state.notification_count.order_ticket = action.payload
    },
    setProduct: (state, action) => {
      state.notification_count.product = action.payload
    },
    setBooking: (state, action) => {
      state.notification_count.booking = action.payload
    },
    setNotificationStatus: (state, action) => {
      state.notificationStatus = !state.notificationStatus
    },
    setShowNewBooking: (state, action) => {
      state.showNewBooking = action.payload
    },
    setShowNewOrder: (state, action) => {
      state.showNewOrder = action.payload
    },
  },
  extraReducers: {
    [getNotification.fulfilled]: (state, action) => {
      state.notification_count = {
        product: action.payload.product_order_count,
        order_ticket: action.payload.ticket_order_count,
        booking: action.payload.inspection_count,
      }
    },
    [getBookingNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.notificationList = action.payload.data.aaData;
    },
    [getBookingNotification.pending]: (state) => {
        state.loading = true;
    },
  },
})

export const { 
  setRefresh, 
  setProduct, 
  setBooking, 
  setOrderTicket, 
  setNotificationStatus, 
  setShowNewBooking, 
  setShowNewOrder
} = dataSlice.actions

export default dataSlice.reducer
