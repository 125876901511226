import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter  } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'

//toast
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

//css
import './assets/styles/components/_one-signal.css';

//utility
import { initializeOneSignal, observeNotificationChanges } from 'utils/one_signal/OneSignalSetup'

const environment = process.env.NODE_ENV

if (appConfig.enableMock) {
	mockServer({ environment })
}

function App() {
	const [initialized, setInitialized] = useState(false);
	const [notificationPermission, setNotificationPermission] = useState("default");

	useEffect(() => {
	  initializeOneSignal(setInitialized, setNotificationPermission);
	}, []);
		
	useEffect(() => {
	  observeNotificationChanges(notificationPermission);
	}, [notificationPermission]);

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter history={history}>
					<Theme>
						<Layout />
					</Theme>
					<ToastContainer/>
				</BrowserRouter >
			</PersistGate>
		</Provider>
	)
}

export default App
