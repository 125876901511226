import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: "",
    username: "",
    name: "",
    id:"",
    authority: []
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setOpenModal: (state,action) => {
            state.openModal = action.payload
          },
          setOpenProfileModal: (state,action) => {
            state.openProfileModal = action.payload
          },
          setOpenWithdrawalModal: (state,action) => {
            state.openWithdrawalModal = action.payload
          },
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
	},
})

export const { setUser,setOpenProfileModal,setOpenModal,setOpenWithdrawalModal } = userSlice.actions

export default userSlice.reducer