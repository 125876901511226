import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState, setOpenModal, setOpenProfileModal, setOpenWithdrawalModal } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
			// const resp = await apiSignIn(values)

			const { data } = await apiSignIn(values)

			if (data) {
				const { token, role, username, name, id, avatar } = data.data

				dispatch(onSignInSuccess(token))
				localStorage.setItem("role", role);
				localStorage.setItem("token", token);

				if( role == "superadmin" || role == "admin") {
				  dispatch(
					setUser({
					  avatar: avatar,
					  username: username,
					  authority: [role],
					  name: name,
					  id: id,
					})
				  )
				//   navigate(appConfig.authenticatedEntryPath)
				} else {
				  dispatch(
					setUser({
					  avatar: avatar,
					  username: username,
					  authority: [role],
					  name: name,
					  id: id,
					})
				  );
				//   navigate(appConfig.otherEntryPath);
				}
		  
				// return {
				//   status: "success",
				//   message: "",
				// }
			  }

			// if (resp.data) {
			// 	const { token } = resp.data
			// 	dispatch(onSignInSuccess(token))
			// 	if(resp.data.user) {
			// 		dispatch(setUser(resp.data.user || { 
			// 			avatar: '', 
			// 			userName: 'Anonymous', 
			// 			authority: ['USER'], 
			// 			email: ''
			// 		}))
			// 	}
			// 	const redirectUrl = query.get(REDIRECT_URL_KEY)
			// 	navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
            //     return {
            //         status: 'success',
            //         message: ''
            //     }
			// }
		} catch (errors) {

			if (errors && errors.response.status === 422)
			{

			  return {
				status: "failed",
				message: errors?.response?.data?.errors?.password?.[0] || errors.toString(),
			  }
			}
			return {
			  status: "failed",
			  message: errors?.response?.data?.message || errors.toString(),
			}
		}
    }

	const signUp = async (values) => {
        // try {
		// 	const resp = await apiSignUp(values)
		// 	if (resp.data) {
		// 		const { token } = resp.data
		// 		dispatch(onSignInSuccess(token))
		// 		if(resp.data.user) {
		// 			dispatch(setUser(resp.data.user || { 
		// 				avatar: '', 
		// 				userName: 'Anonymous', 
		// 				authority: ['USER'], 
		// 				email: ''
		// 			}))
		// 		}
		// 		const redirectUrl = query.get(REDIRECT_URL_KEY)
		// 		navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
        //         return {
        //             status: 'success',
        //             message: ''
        //         }
		// 	}
		// } catch (errors) {
		// 	return {
        //         status: 'failed',
        //         message: errors?.response?.data?.message || errors.toString()
        //     }
		// }
    }

    const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		localStorage.clear()
		navigate(appConfig.unAuthenticatedEntryPath)
	}

    const signOut = async () => {
		// await apiSignOut()
		handleSignOut()
	}
    
	const changePassword = async (values) => {
		dispatch(setOpenModal(true))
	  }
	  
	  const editProfile = async (values) => {
		dispatch(setOpenProfileModal(true))
	  }

	  const withdrawalPoint = async (values) => {
		dispatch(setOpenWithdrawalModal(true))
	  }
	  
    return {
        authenticated: token && signedIn,
        signIn,
		signUp,
        signOut,
		changePassword,
		editProfile,
		withdrawalPoint
    }
}

export default useAuth