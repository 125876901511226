import ApiService from "./ApiService"

export async function apiGetNotificationCount () {
    return ApiService.fetchData({
        url: '/notification/count',
        method: 'get'
    })
}

export async function apiGetNotificationList () {
    return ApiService.fetchData({
        url: '/notification/list',
        method: 'get'
    })
}

export async function apiGetSearchResult (data) {
    return ApiService.fetchData({
        url: '/search/query',
        method: 'post',
        data
    })
}

export async function notification(data) {
    return ApiService.fetchData({
      url:
        process.env.REACT_APP_API_URL +
        `/admin_notification_count`,
      method: "get",
      data,
    })
  };

export async function getBookingNotificationList(payload) {
    return ApiService.fetchData({
        url:
        process.env.REACT_APP_API_URL +
        `/notifications?start=${payload.start ?? ""}&length=${payload.length}`,
        method: "get",
    })
};

export async function patchBookingNotification(data) {
    return ApiService.fetchData({
        url:
        process.env.REACT_APP_API_URL +
        `/notifications/read/all`,
        method: "patch",
        data,
    })
};